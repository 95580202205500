import React, { useEffect, useState } from 'react'
import styles from './Insoles.module.css'
import axios from 'axios';

const Insoles = () => {
  const [post, setPost] = useState([]);

  useEffect(() => {
    axios.get('https://venusecco.zyberapi.site/post')
    .then((e) => setPost(e.data))
    .catch((e) => alert(e))
  });

  return (
    <div className={styles.container}>
      <h1>Улавчнууд</h1>
      <p>Та өөрийн хүссэн улавчаа сонгоно уу!</p>
      <div className={styles.items}>
        {/* {[...Array(8)].map((_, index) => (
            <div className={styles.card}>
              <img src={img} alt="" />
              <div className={styles.info}>
                  <h2>Хэмжээ: 38.5</h2>
                  <h2>Эрэгтэй</h2>
              </div>
            </div>
        ))} */}
        {post.map((e) => (
          <div className={styles.card}>
            <img src={'https://venusecco.zyberapi.site/' + e.cover} alt="" />
            <div className={styles.info}>
                <h2>Хэмжээ: {e.size}</h2>
                <h2>{e.sex}</h2>
            </div>
          </div>
          ))}
      </div>
    </div>
  )
}

export default Insoles
