import React from 'react'
import styles from './Home.module.css';
// import img from '../../Assets/3removebg.png'
import svg from '../../Assets/e.png'
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <img src={svg} alt="" />
      </div>
      <div className={styles.items}>
        <h2>VenusEcco</h2>
        <p>Улны хэлбэрт тохирсон улавчтай бөгөөд хөлний нум зөв хэлбэржихэд тусална. Мөн чийгтэй улавч нь өвчний эх үүсвэр болдог учир манай улавч нь чийг татахгүй цэвэр арьсаар хийгдэв.</p>
        <h3>ISO-9001 стандарт хангасан ургамлын идээлгээр боловсруулсан арьс</h3>
        <Link to={'/insoles'} className={styles.link}>Бараа үзэх</Link>
      </div>
    </div>
  )
}

export default Home
