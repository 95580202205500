import React from 'react'
import styles from './Navbar.module.css'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <div className={styles.container}>
      <h1>VenusEcco</h1>
      <div>
        <Link to={'/'} className={styles.link}>Нүүр</Link>
        <Link to={'/insoles'} className={styles.link}>Бараа</Link>
      </div>
    </div>
  )
}

export default Navbar
