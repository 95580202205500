import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import Insoles from './Pages/Insoles/Insoles';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/insoles' element={<Insoles />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
